import React,{Component} from  'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {EmptyDay} from './../planning/Day';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import * as ResourceStore from '../../store/ResourceStore';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

class ResourceCalendarLine extends Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const plansDifferent = JSON.stringify(this.props.plans) !== JSON.stringify(nextProps.plans);
        const daysDifferent = this.props.days[0] !== nextProps.days[0] ||
            this.props.days.length !== nextProps.days.length;
        const resStateDifferent = this.props.resState.showDeleteBox !== nextProps.resState.showDeleteBox;
    
        return plansDifferent || daysDifferent || resStateDifferent;
    }
    

    render() {
        const props = this.props;
        const unit = props.unit;
        const granularity = props.granularity;
        const days = props.days;
        const dayWidth = props.dayWidth;
        const plans = props.plans;
    
        const grid = days.map((d, i) => {
            return <EmptyDay key={i} size={dayWidth} highlight={false} />;
        });
        const hourGrid = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((d, i) => {
            return <EmptyDay key={i} size={dayWidth} highlight={false} />;
        });
    
        return (
            <div className="row" onClick={props.onClick || null}>
                <div className='plans'>
                    {granularity === 'hour' ? <PlanComponentsHour startTime={props.startTime} plans={plans} dayWidth={dayWidth} resourceActions={props.resourceActions} resState={props.resState} /> : <PlanComponents startTime={props.startTime} plans={props.plans} dayWidth={dayWidth} resourceActions={props.resourceActions} />}
                </div>
                <div className='cell-grid'>
                    {granularity === 'hour' ? hourGrid : grid}
                </div>
                <div className='name'>{unit.name}</div>
            </div>
        );
    };    
}

ResourceCalendarLine.propTypes = {
    unit: PropTypes.object.isRequired,
    granularity: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    plans: PropTypes.array,
    startTime: PropTypes.any.isRequired,
    resourceActions: PropTypes.object.isRequired,
    resState: PropTypes.object.isRequired,
};

const PlanComponentsHour = props => {
    const start = moment(props.startTime);
    const dayWidth = props.dayWidth;
    return props.plans.map((p,i) => {
        let planStart = moment(p.startTime);
        if(planStart.isBefore(start)) planStart = start;
        const startTime = moment(planStart).diff(props.startTime, 'minutes') / 60 * dayWidth;
        const duration = moment(p.endTime).diff(planStart, 'minutes') / 60;
        return <Plan key={i} dayWidth={dayWidth} startTime={startTime} duration={duration} p={p} resourceActions={props.resourceActions} resState={props.resState} />;
    })
};

PlanComponentsHour.propTypes = {
    startTime: PropTypes.object,
    plans: PropTypes.array,
    dayWidth: PropTypes.number,
    resourceActions: PropTypes.object.isRequired,
    resState: PropTypes.object.isRequired,
};

const PlanComponents = props => {
    const start = moment(props.startTime);
    const dayWidth = props.dayWidth;

    return props.plans.map((p,i) => {
        let planStart = moment(p.startTime);
        if(planStart.isBefore(start)) planStart = moment(start).add(1, 'hour');
        const planEnd = moment(p.endTime);
        const startTime = Math.ceil(((planStart - start) / _MS_PER_DAY) - 1) * dayWidth;
        const duration = Math.ceil((planEnd - planStart) / _MS_PER_DAY);
        return <Plan key={i} dayWidth={dayWidth} startTime={startTime} duration={duration} p={p} resourceActions={props.resourceActions} resState={props.resState} />;
    });
};

const Plan = props => {
    const duration = props.duration;
    const startTime = props.startTime;
    const p = props.p;
    const dayWidth = props.dayWidth;

    const toggleDeleteBox = (p) => {
        props.resourceActions.toggleShowDeleteBox(p);
    };
    const createDeleteBox = (p, id) => {  
        return (<div className="delete-box">
            <div className="background"></div>
            <div className="text-wrapper">
                Vil du slette {p.person.name.trim().split(/\s+/)[0]}'s booking af {p.unit.name}?
            </div>
            <button className="delete-button" onClick={() => props.resourceActions.deleteBooking(id)}>Slet</button>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-x-circle-fill close" viewBox="0 0 16 16" onClick={(event) => { event.stopPropagation(); toggleDeleteBox(); }}>
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                </svg>
        </div>) ;
    }

    if(duration > 0){
        return(
            <div key={p.id} className='plan' style={{left: `${startTime}%`, backgroundColor: 'red', width: `${dayWidth*duration}%`}} onClick={() => toggleDeleteBox(p)}>
                {props.resState && props.resState.showDeleteBox && props.resState.bookingIdToDelete == p.id && createDeleteBox(p, props.resState.bookingIdToDelete)}
                <div className='plan-text'>
                    {`${p.personId ? `${p.person.name}` : ''}${p.personId && p.teamId ? ' - ' : ''}${p.teamId ? p.team.name : ''}`}
                </div>
            </div>
        )
    }
    return null;
};

Plan.propTypes = {
    startTime: PropTypes.object,
    duration: PropTypes.number,
    dayWidth: PropTypes.number,
    p: PropTypes.object,
    resourceActions: PropTypes.object.isRequired,
    resState: PropTypes.object.isRequired,
};

PlanComponents.propTypes = {
    startTime: PropTypes.object,
    plans: PropTypes.array,
    dayWidth: PropTypes.number,
    resourceActions: PropTypes.object.isRequired,
    resState: PropTypes.object.isRequired,
};

export default connect(
    state => ({
        resState: state[ResourceStore.reducerName]
    }),
    dispatch => ({
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
    })
)(ResourceCalendarLine);